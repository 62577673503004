<template>
  <div id="report-device-scan" class="main-report">
    <h2>
      <span><i class="far fa-list-alt"></i></span> {{ $t("deviceScan.title") }}
    </h2>
    <v-sheet class="card-select-date" width="100%" height="120px">
      <v-row dense>
        <v-col cols="3" md="3" lg="3">
          <label class="label-input">{{ $t("Employee.search_filter") }}</label>
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="..."
            v-model="searchItem"
            @keypress.enter="fetchDeviceScanList"
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" md="3" lg="3">
          <label class="label-input">{{ $t("deviceScan.startDate") }}</label>
          <v-menu
            v-model="startDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="start_date"
              @input="startDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" md="3" lg="3">
          <label class="label-input">{{ $t("deviceScan.endDate") }}</label>
          <v-menu
            v-model="endDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              @input="endDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" md="3" lg="3">
          <v-btn class="btn-action mt-7" @click="fetchDeviceScanList">
            <i class="fa fa-search" aria-hidden="true"></i>
            {{ $t("deviceScan.search") }}
          </v-btn>

          <v-btn class="btn-action confirm mt-7" @click="exportExcel">
            <i class="fa fa-file-excel"></i> export
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      class="mt-4 pa-4 table-todolist"
      width="100%"
      height="auto"
      color="white"
    >
      <table class="table table-view">
        <tr>
          <th>No:</th>
          <th>{{ $t("deviceScan.emp_id") }}</th>
          <th>{{ $t("deviceScan.emp_name") }}</th>
          <th>{{ $t("deviceScan.branch") }}</th>
          <th>{{ $t("deviceScan.depart_ment") }}</th>
          <th>{{ $t("deviceScan.device_list") }}</th>
        </tr>
        <tbody>
          <tr v-for="(item, index) in deviceScanList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.emp_number }}</td>
            <td>{{ item.name }} {{ item.surname }}</td>
            <td>{{ item.branch }}</td>
            <td>{{ item.department }}</td>
            <td>
              <span
                v-for="(device_item, i) in item.device"
                :key="i"
                class="device"
              >
                <i class="fa fa-mobile" aria-hidden="true"></i>
                <b> {{ device_item.phone_model }}</b>
                [<b> {{ device_item.phone_id }}</b
                >]
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        v-if="pagination.total_pages > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchDeviceScanList"
      >
      </Pagination>
    </v-sheet>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
export default {
  name: "deviceScan",
  components: {
    Loading,
    Pagination,
  },
  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: false,
      startDate: false,
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      deviceScanList: [],
      isLoading: false,
    };
  },
  methods: {
    exportExcel() {
      const item = {
        searchItem: this.searchItem,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
      };
      this.isLoading = true;
      this.$axios
        .post(`company/export-tracking-scan`, item, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "scaning-device.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchDeviceScanList() {
      this.isLoading = true;
      this.$axios
        .post(`company/report-tracking-scan`, {
          searchItem: this.searchItem,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          per_page: this.per_page,
          page: this.pagination.current_page,
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.deviceScanList = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.deviceScanList.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
    this.fetchDeviceScanList();
  },
};
</script>

<style scoped lang="scss">
.device {
  background-color: antiquewhite;
  margin-right: 10px;
}
.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  padding: 8px;
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    display: flex;
    align-items: center;
    padding: 8px;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}
</style>