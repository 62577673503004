<template>
    <div class="m-5 lfont">
        <v-row>
            <v-col cols="12">
                <h1 class="text-primary"><v-icon class="text-primary">mdi-file-edit-outline</v-icon> {{
                    $t("report_clock_in_out_corection.title") }}
                </h1>
                <hr>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="2" lg="2">
                <label for="">{{ $t("report_clock_in_out_corection.year") }}</label>
                <v-select dense color="pink" required solo :items="years" v-model="year"
                    @change="fetch_clock_in_out_correction"></v-select>
            </v-col>
            <v-col cols="12" md="2" lg="2">
                <label for="">{{ $t("report_clock_in_out_corection.month") }}</label>
                <v-select dense color="pink" required solo :items="months" v-model="month"
                    @change="fetch_clock_in_out_correction"></v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3">
                <label class="label-input">{{
                    $t("ReportScanInOut.textsearchByname")
                    }}</label>
                <v-text-field dense prepend-inner-icon="mdi-magnify" placeholder="employee name.." solo
                    @keypress.enter="fetch_clock_in_out_correction" v-model="search">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div v-if="clock_in_out_correction != null">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="50">#</th>
                                <th>ລະຫັດພະນັກງານ</th>
                                <th>ຊື່ພະນັກງານ</th>
                                <th>ຈຳນວນຄັ້ງທີ່ແກ້ໄຂ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in clock_in_out_correction" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.emp_number }}</td>
                                <td>{{ item.name }} {{ item.surname }}</td>
                                <td><span class="text-primary qty-correction"
                                        @click="show_clock_in_out_correction_detail(item)">{{
                                            item.clock_in_corrections.length }}</span></td>
                            </tr>
                        </tbody>

                    </table>
                    <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
                        @paginate="fetch_clock_in_out_correction">
                    </Pagination>
                </div>
            </v-col>
        </v-row>
        <loading v-if="isLoading" />
        <ShowClockInOutCorrection :dialog="dialog_show_detail" @close="dialog_show_detail = false"
            :clock_in_out_correction="clock_in_out_correction_detail" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import ShowClockInOutCorrection from "./Modals/ShowClockInOutCorrection.vue";

export default {
    components: {
        Loading,
        Pagination,
        ShowClockInOutCorrection,
    },
    data() {
        const currentYear = new Date().getFullYear();
        return {
            dialog_show_detail: false,
            page: 1,
            offset: 10,
            search: "",
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            years: [currentYear - 2, currentYear - 1, currentYear],
            year: currentYear,
            month: null,
            isLoading: false,
            clock_in_out_correction: null,
            pagination: {
                current_page: 1,
            },
            clock_in_out_correction_detail: null,
        }
    },
    methods: {
        fetch_clock_in_out_correction() {
            let filters = {
                page: this.pagination.current_page,
                per_page: this.offset,
                year: this.year,
                month: this.month,
                search: this.search
            };
            this.isLoading = true;
            this.$axios
                .get("company/report/employee/clockin/correction", { params: filters })
                .then((res) => {
                    this.isLoading = false;
                    this.clock_in_out_correction = res.data.data.data;
                    this.pagination = res.data.data.paginate;
                    if (!this.clock_in_out_correction.length > 0) {
                        this.pagination.current_page = this.pagination.current_page - 1;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        },
        show_clock_in_out_correction_detail(item) {
            if (item.clock_in_corrections.length > 0) {
                console.log(item);
                this.dialog_show_detail = true;
                this.clock_in_out_correction_detail = item;
            }
        }
    },
    created() {
        this.fetch_clock_in_out_correction();
    }
}
</script>

<style scoped>
.table tr th {
    border: 1px solid rgb(156, 156, 156) !important;
    background-color: rgb(233, 233, 233);
}

.table tr td {
    border: 1px solid rgb(156, 156, 156) !important;
}

.qty-correction {
    cursor: pointer;
}
</style>
