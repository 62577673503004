<template>
    <div>
        <v-dialog v-model="showModal" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="$emit('close', false)" class="text-danger">
                            Close
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider></v-divider>
                <v-list three-line subheader>
                    <div class="p-5 lfont" v-if="clock_in_out_correction">
                        <h3>{{
                            $t("report_clock_in_out_corection.clock_in_out_corection_detail.emp_number") }}:
                            <span class="text-primary">{{ clock_in_out_correction.emp_number }}</span>
                        </h3>
                        <h3>{{
                            $t("report_clock_in_out_corection.clock_in_out_corection_detail.emp_name") }}:
                            <span class="text-primary">{{ clock_in_out_correction.name }} {{
                                clock_in_out_correction.surname }}</span>
                        </h3>
                        <h3>{{
                            $t("report_clock_in_out_corection.clock_in_out_corection_detail.branch") }}:
                            <span class="text-primary">{{ clock_in_out_correction.name }} {{
                                clock_in_out_correction.surname }}</span>
                        </h3>
                        <h3>{{
                            $t("report_clock_in_out_corection.clock_in_out_corection_detail.dept") }}:
                            <span class="text-primary">{{ clock_in_out_correction.name }} {{
                                clock_in_out_correction.surname }}</span>
                        </h3>
                        <table class="table table-view">
                            <tr>
                                <th width="50">#</th>
                                <th>{{ $t("report_clock_in_out_corection.table.date") }}
                                </th>
                                <th>{{ $t("report_clock_in_out_corection.table.schedule")
                                    }}</th>
                                <th>{{ $t("report_clock_in_out_corection.table.clock_in")
                                    }}</th>
                                <th>{{
                                    $t("report_clock_in_out_corection.table.correction")
                                }}</th>
                            </tr>
                            <tbody>
                                <tr v-for="(item, index) in clock_in_out_correction.clock_in_corrections" :key="index"
                                    class="text-primary">
                                    <td>{{ index + 1 }}</td>
                                    <td><v-icon>mdi-calendar-alert</v-icon> {{ item.created_at }}</td>
                                    <td>
                                        <span>{{ item.schedule_name }}->{{ item.schedule_detail_name }}</span>
                                    </td>
                                    <td>
                                        <span><v-icon>mdi-clock</v-icon> {{ item.from_clock_in_time }}</span>
                                    </td>
                                    <td class="text-danger">
                                        <span><v-icon class="text-danger">mdi-clock</v-icon> {{ item.to_clock_in_time
                                            }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['dialog', 'clock_in_out_correction'],
    computed: {
        showModal: function () {
            return this.dialog;
        },
    },
}
</script>

<style lang="scss" scoped></style>