<template>
    <div>
        <div class="header-container">
            <v-breadcrumbs>
                <v-breadcrumbs-item class="breadcrumbs-hover-default">
                    <i class="fal fa-address-card" style="margin: 0 10px 0 0"></i>
                    <span>{{ $t("clockin_out_outside.title") }}</span>
                </v-breadcrumbs-item>
            </v-breadcrumbs>
        </div>
        <div class="page-content">
            <div class="content">
                <v-row class="mb-2">
                    <v-col cols="12" md="3" sm="3">
                        <label class="label-input">{{ $t("clockin_out_outside.start_date") }}</label>
                        <DatePicker class="date-time" style="width: 100%" type="date" v-model="start_date"
                            valueType="format" slot="activator" :append-to-body="true"
                            @change="fetchEmployeeClockInOutside"></DatePicker>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <label class="label-input">{{ $t("clockin_out_outside.end_date") }}</label>
                        <DatePicker class="date-time" style="width: 100%" type="date" v-model="end_date" valueType="format"
                            slot="activator" :append-to-body="true" @change="fetchEmployeeClockInOutside"></DatePicker>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="12" md="3" sm="3">
                        <label class="label-input">{{ $t("clockin_out_outside.search_title") }}</label>
                        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="filter"
                            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')"
                            @keypress.enter="fetchEmployeeClockInOutside">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" lg="3">
                        <label class="label-input">{{ $t("Employee.dept") }}</label>
                        <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                            hide-selected v-model="departmentId" @change="fetchEmployeeClockInOutside">
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" lg="3">
                        <label class="label-input">{{ $t("Employee.branch") }}</label>
                        <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                            hide-selected v-model="branchId" @change="fetchEmployeeClockInOutside">
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col clos="12" md="12" sm="12">
                        <table class="table table-view">
                            <tr>
                                <th>#</th>
                                <th>{{ $t("clockin_out_outside.table.emp_number") }}</th>
                                <th>{{ $t("clockin_out_outside.table.emp_name") }}</th>
                                <th>{{ $t("clockin_out_outside.table.department") }}</th>
                                <th>{{ $t("clockin_out_outside.table.branch") }}</th>
                                <th>{{ $t("clockin_out_outside.table.image") }}</th>
                                <th></th>
                                <th>{{ $t("clockin_out_outside.table.scan_point") }}</th>
                                <th>{{ $t("clockin_out_outside.table.scan_time") }}</th>
                                <th>{{ $t("clockin_out_outside.table.note") }}</th>
                            </tr>
                            <tbody>
                                <tr v-for="(item, index) in employeeData">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.emp_number }}</td>
                                    <td>{{ item.name }} {{ item.surname }}</td>
                                    <td>{{ item.dept }}</td>
                                    <td>{{ item.branch }}</td>
                                    <td>
                                        <a :href="item.file" target="_blank" v-if="item.file">
                                            <img :src="item.file" alt="" width="50" height="50">
                                        </a>
                                    </td>
                                    <td>
                                        <span v-if="item.clock_type == 'in'">ສະແກນ ເຂົ້າ</span>
                                        <span v-else>ສະແກນ ອອກ</span>
                                    </td>
                                    <td>
                                        <v-btn x-small class="ma-2" outlined fab color="teal">
                                            <a v-bind:href="'https://www.google.com/maps/dir//' +
                                                item.latitude +
                                                ',' +
                                                item.longitude +
                                                '/@' +
                                                item.latitude +
                                                ',' +
                                                item.longitude +
                                                ',400m/data=!3m1!1e3!4m2!4m1!3e0'
                                                " target="_blank">
                                                <v-icon>mdi-map-marker-outline</v-icon>
                                            </a>
                                        </v-btn>
                                    </td>
                                    <td>{{ item.scan_at }}</td>
                                    <td>{{ item.note }}</td>
                                </tr>
                            </tbody>

                        </table>
                        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
                            @paginate="fetchEmployeeClockInOutside">
                        </Pagination>
                    </v-col>
                </v-row>
                <!-- <router-view /> -->

            </div>
        </div>
        <loading v-if="isLoading" />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
export default {
    components: {
        DatePicker,
        Loading,
        Pagination
    },
    data() {
        return {
            isLoading: false,
            employeeData: [],
            offset: 10,
            pagination: {},
            per_page: 10,
            start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            filter: "",
            branchId: "",
            departmentId: "",
            listDepartment: [],
            listBranch: []
        };
    },

    methods: {
        fetchEmployeeClockInOutside() {
            if (this.start_date > this.end_date) return;
            this.isLoading = true;
            this.$axios
                .get(`company/report/employee/clockin/outside`, {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                        filter: this.filter,
                        branchId: this.branchId,
                        departmentId: this.departmentId,
                        start_date: this.start_date,
                        end_date: this.end_date,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        this.employeeData = res.data.data.data;
                        this.pagination = res.data.data.pagination;
                        if (!this.employeeData.length > 0) {
                            this.pagination.current_page = this.pagination.current_page - 1;
                        }
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        fetchDepartment() {
            this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
                if (res.status === 200) {
                    this.listDepartment = res.data.data;
                }
            });
        },

        fetchBranch() {
            this.$axios
                .get(`company/list/company/branches`)
                .then((res) => {
                    if (res) {
                        this.listBranch = res.data.companyBranches;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    created() {
        this.fetchEmployeeClockInOutside();
        this.fetchDepartment();
        this.fetchBranch();
    },
}
</script>

<style lang="scss" scoped>
.content {
    padding: 20px;
}
</style>