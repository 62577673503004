<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="lfont ml-2">{{ $t("modal_absence_report.title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mb-4">
                <date-picker class="date-time" style="width: 100%" type="time" valueType="format" slot="activator"
                  :append-to-body="true" name="founding_date" v-model="change_time"></date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="Notes" auto-grow outlined v-model="remark" rows="4" row-height="10"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveChange">
            Save
          </v-btn>
          <v-btn color="dark" text @click="closeModal">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { emit } from "process";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  check_in_schedule_detail_id: "",
  props: [
    "dialogR",
    "change_time_id",
    "change_time",
    "employee_id",
    "check_in_schedule_detail_id",
    "clock_type",
    "date",
  ],

  data() {
    return {
      remark: "",
      server_errors: {
        remark: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    updateClockIn() {
      const items = {
        change_time: this.change_time, //moment(this.change_time).format("H:i:s"),
        remark: this.remark,
      };
      this.$axios
        .post(`company/change/employee/clockin/${this.change_time_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    addClockIn() {
      this.$emit('proccess', true);
      this.$emit("close");
      const items = {
        employee_id: this.employee_id,
        check_in_schedule_detail_id: this.check_in_schedule_detail_id,
        clock_type: this.clock_type,
        date: this.date,
        time: this.change_time,
        remark: this.remark,
      };
      this.$axios
        .post(`company/admin/store/clockin`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    saveChange() {
      if (this.change_time_id) {
        this.updateClockIn();
      } else {
        this.addClockIn();
      }
    },
  },
  computed: {
    showModal: function () {
      return this.dialogR;
    },
  },
  created() { },
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}

.btn {
  padding: 30px !important;
}

.btn-save {
  color: white !important;
  margin-right: 30px;
}

.user {
  color: blue;
}
</style>
