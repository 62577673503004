<template>
  <div class="container-report showScroll">
    <div class="card-filter pb-0">
      <v-row>
        <v-col cols="12" lg="3" md="3">
          <h1>
            <i class="far fa-file-excel"></i> {{ $t("ReportScanInOut.title") }}
          </h1>
        </v-col>
        <v-col cols="12" lg="4" md="3">
          <v-radio-group row v-model="check_in_status" @change="FetchData">
            <!-- <v-radio label="All" value="all"></v-radio> -->
            <v-radio label="Normal" value="normal"></v-radio>
            <v-radio label="Roster" value="roster"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <br />
      <v-row style="width: 100%" dense class="d-flex">
        <v-col cols="12" lg="8" md="8">
          <v-row>
            <v-col cols="4" md="4" lg="4">
              <label class="label-input">{{
                $t("ReportScanInOut.textsearchByname")
              }}</label>
              <v-text-field outlined dense v-model="searchItem" prepend-inner-icon="mdi-magnify"
                placeholder="employee name.." @keypress.enter="searchFilterItem">
              </v-text-field>
            </v-col>
            <v-col cols="4" md="4" lg="4">
              <label class="label-input">{{
                $t("ReportScanInOut.table.dept")
              }}</label>
              <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                hide-selected v-model="departmentId" @change="searchFilterItem">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="4" lg="4">
              <label class="label-input">{{
                $t("ReportScanInOut.table.branch")
              }}</label>
              <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                hide-selected v-model="branch_id" @change="searchFilterItem">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="3" lg="3">
              <label class="label-input">{{
                $t("ReportScanInOut.startSearch")
              }}</label>
              <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" name="founding_date" v-model="start_date"></DatePicker>
            </v-col>
            <v-col cols="4" md="3" lg="3">
              <label class="label-input">{{
                $t("ReportScanInOut.endSearch")
              }}</label>
              <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" v-model="end_date" name="founding_date">
              </DatePicker>
            </v-col>
            <v-col cols="4" md="3" lg="3" class="btn-export">
              <!-- <label class="label-input">.</label><br> -->
              <v-btn class="primary mt-2 ml-2" large @click="searchFilterItem"><span><i class="far fa-search"></i>
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" lg="12" class="btn-export">
              <v-btn class="success mt-2 ml-2" large @click="exportClockInOutDefault"><span><i
                    class="far fa-file-excel"></i>
                  Default</span>
              </v-btn>
              <v-btn class="success mt-2 ml-2" large @click="exportAttendanceLog"><span><i
                    class="far fa-file-excel"></i>
                  Raw Attendance Logs</span>
              </v-btn>

              <!-- <v-btn class="success mt-2 ml-2" large @click="exportAttendanceCorrection"><span><i
                    class="far fa-file-excel"></i>
                  Attendance Correction
                </span>
              </v-btn> -->

              <v-btn class="success mt-2 ml-2" large @click="exportStatistic"><span><i class="far fa-file-excel"></i>
                  Statistic </span>
              </v-btn>
              <v-btn class="success mt-2 ml-2" large @click="exportTotalOfAttendance"><span><i
                    class="far fa-file-excel"></i>
                  Total of Attendance
                </span>
              </v-btn>
              <v-btn class="success mt-2 ml-2" large @click="exportInOut"><span><i class="far fa-file-excel"></i>
                  Exports
                  In - Out
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="2" md="2">
          <div class="card-color-detail">
            <v-row>
              <v-col cols="12" md="12" lg="12">
                <div class="color-detail">
                  <div class="circle-color standard"></div>
                  <p>{{ $t("ReportScanInOut.statusNormal") }}</p>
                </div>
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <div class="color-detail">
                  <div class="circle-color is-skip"></div>
                  <p>{{ $t("ReportScanInOut.statusDefective") }}</p>
                </div>
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <div class="color-detail">
                  <div class="circle-color is-absence"></div>
                  <p>{{ $t("ReportScanInOut.statusAbsence") }}</p>
                </div>
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <div class="color-detail">
                  <div class="circle-color is-leave"></div>
                  <p>{{ $t("ReportScanInOut.statusLeave") }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <br />
      <br />
    </div>

    <div class="card-report">
      <div>
        <!-- :style="{ marginTop: '350px' }" -->

        <!-- <div v-show="false">
          <button class="button" @click="ExportExcel()">Export Excel</button>
        </div> -->
        <table id="mytable">
          <thead style="
              border-bottom: 1px solid black;
              background-color: #009fff;
              color: #ffffff;
            ">
            <th class="text-no-wrap" style="
                /* padding: 10px; */
                border-bottom: 1px solid black;
                color: #ffffff;
                font-weight: 400;
              ">
              {{ $t("ReportScanInOut.table.emp_number") }}
            </th>
            <th class="text-no-wrap" style="
                /* padding: 10px; */
                border-bottom: 1px solid black;
                color: #ffffff;
                font-weight: 400;
              ">
              {{ $t("ReportScanInOut.table.fullName") }}
            </th>
            <!-- <th
              class="text-no-wrap"
              style="
                padding: 20px;
                border-bottom: 1px solid black;
                color: #ffffff;
                font-weight: 400;
              "
            >
              {{ $t("ReportScanInOut.table.dept") }}
            </th> -->
            <th class="text-no-wrap" style="
                /* padding-left: 100px; */
                padding-top: 10px;
                color: #ffffff;
                font-weight: 400;
              " v-for="(date, index) in dates" :key="index">
              {{ formatDate(date.date) }} [{{ date.week_day }}]
            </th>
          </thead>
          <tr v-for="employee in employees" :key="employee.id" style="height: auto; position: relative">
            <td style="
                /* background-color: white;*/
                /*  position: absolute;*/
                /*  display: inline-block;*/
                /*  height: 100%;*/
                /*  height: 80px;*/
                /*  width: 100px;*/
                /*//  border-left: 1.5px solid #eeeeee;*/
                          /*  padding-left: 12px;*/
                /*  font-size: 14px;*/
                /*  overflow: hidden;*/
                /*  text-overflow: ellipsis;*/
                /*  display: -webkit-box;*/
                /*  -webkit-line-clamp: 2;*/
                /*  -webkit-box-orient: vertical;*/
                /*  left: 0;*/
                /*  border-bottom: 1px solid black;*/
                /*  border-left: 1px solid black;*/
                /*  border-right: 1px solid black;*/
                /*  background-color: red;*/
                display: inline-block;
                background-color: #ffffff;
                border: 1px solid black;
                padding: 10px;
              ">
              {{ employee.emp_number }}
              <!-- <span style="position: absolute">
                {{ employee.name }} {{ employee.surname }}
              </span> -->
            </td>
            <td style="
                /* background-color: white;*/
                /*  position: absolute;*/
                /*  display: inline-block;*/
                /*  height: 100%;*/
                /*  height: 80px;*/
                /*  width: 100px;*/
                /*//  border-left: 1.5px solid #eeeeee;*/
                          /*  padding-left: 12px;*/
                /*  font-size: 14px;*/
                /*  overflow: hidden;*/
                /*  text-overflow: ellipsis;*/
                /*  display: -webkit-box;*/
                /*  -webkit-line-clamp: 2;*/
                /*  -webkit-box-orient: vertical;*/
                /*  left: 0;*/
                /*  border-bottom: 1px solid black;*/
                /*  border-left: 1px solid black;*/
                /*  border-right: 1px solid black;*/
                /*  background-color: red;*/
                display: inline-block;
                background-color: #ffffff;
                border: 1px solid black;
                padding: 10px;
              ">
              {{ employee.name }} {{ employee.surname }}
              <!-- <span style="position: absolute">
                {{ employee.name }} {{ employee.surname }}
              </span> -->
            </td>
            <td v-for="(date, index) in dates" :key="index" style="border: 1px solid black">
              <div v-if="employee.finger_scan == 'yes'" :style="{
                paddingLeft: '40px',
                paddingTop: '8px',
                paddingRight: '8px',
                minHeight: '80px',
              }">
                <div v-for="(part, index) in employee.parts" :key="index">
                  <!-- ##### ສ່ວນທີ່ການຕັ້ງເວລາສະແກນ ບໍ່ມີການເລືອກການສະແກນມື້ຂອງອາທິດເຂົ້າສະແກນ ##### -->

                  <div v-if="part.dayScan.length == 0">
                    <div v-for="(timePart, i) in part.empSchedule" :key="i">
                      <div class="td-part-name" :style="{
                        backgroundColor: getColor(employee, part, date),
                        color: getFontColor(getColor(employee, part, date)),
                        borderRadius: '2px',
                        marginBottom: '4px',
                        border: '1px solid #000000',
                      }" v-if="check_time_clock_in(timePart.started_at, date.date, timePart.ended_at)">
                        <v-tooltip top color="info">
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn v-bind="attrs" v-on="on"> -->
                            <div v-bind="attrs" v-on="on" style="cursor: pointer;min-height: 25px;padding-top: 5px;">
                              <span v-for="(schedule_item, i) in getTimeScan(
                                employee,
                                part,
                                date
                              )" :key="i">
                                {{ schedule_item.data }}
                                <span v-if="schedule_item.status ||
                                  schedule_item.data == ''
                                ">
                                  <v-btn icon x-small color="green" @click="
                                    modalUpdateTime(
                                      employee.id,
                                      employee.dates,
                                      employee.clock_date_leave,
                                      date,
                                      part
                                    )
                                    ">
                                    <v-icon>mdi-pen</v-icon>
                                  </v-btn>

                                  <v-btn class="ml-2" icon x-small color="primary" @click="
                                    deleteTimeScan(employee.dates, employee.clock_date_leave, date, part)
                                    ">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </span>
                              </span>

                              <!-- <span v-else></span> -->
                            </div>
                            <!-- </v-btn> -->
                          </template>

                          <div class="content-tooltip" v-if="date.rest_day == null">
                            <h4 class="tooltip-header-title text-center">
                              {{ part.name }}
                            </h4>
                            <div class="tooltip-detail">
                              <p v-if="part.clock_type == 'in'">
                                Check In :
                                <span>{{ part.check_in_before }}</span>
                              </p>
                              <p v-else>
                                Check Out :
                                <span>{{ part.check_out_after }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="content-tooltip" v-else>
                            <p class="lfont">{{ date.rest_day }}</p>
                          </div>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <!-- ##### ສ່ວນທີ່ການຕັ້ງເວລາສະແກນ ບໍ່ມີການເລືອກການສະແກນມື້ຂອງອາທິດເຂົ້າສະແກນ ##### -->

                  <!--##### ສ່ວນທີ່ການຕັ້ງເວລາສະແກນ ມີການເລືອກການສະແກນມື້ຂອງອາທິດເຂົ້າສະແກນ ##### -->
                  <div v-else>
                    <div v-for="(dayScan, k) in part.dayScan" :key="k">
                      <div v-if="dayScan.week_day == date.week_day">
                        <div v-for="(timePart, i) in part.empSchedule" :key="i">
                          <div class="td-part-name" :style="{
                            backgroundColor: getColor(employee, part, date),
                            color: getFontColor(
                              getColor(employee, part, date)
                            ),
                            borderRadius: '2px',
                            marginBottom: '4px',
                            border: '1px solid #000000',
                          }" v-if="check_time_clock_in(timePart.started_at, date.date, timePart.ended_at)">
                            <v-tooltip top color="info">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" style="cursor: pointer; min-height: 25px">
                                  <span v-for="(schedule_item, i) in getTimeScan(
                                    employee,
                                    part,
                                    date
                                  )" :key="i">
                                    {{ schedule_item.data }}
                                    <span v-if="schedule_item.status ||
                                      schedule_item.data == ''
                                    ">
                                      <v-btn icon x-small color="green" @click="
                                        modalUpdateTime(
                                          employee.id,
                                          employee.dates,
                                          employee.clock_date_leave,
                                          date,
                                          part
                                        )
                                        ">
                                        <v-icon>mdi-pen</v-icon>
                                      </v-btn>
                                      <v-btn class="ml-2" icon x-small color="primary" @click="
                                        deleteTimeScan(employee.dates, employee.clock_date_leave, date, part)
                                        ">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </span>
                                  </span>
                                </div>
                              </template>
                              <div class="content-tooltip" v-if="date.rest_day == null">
                                <h4 class="tooltip-header-title text-center">
                                  {{ part.name }}
                                </h4>
                                <div class="tooltip-detail">
                                  <p v-if="part.clock_type == 'in'">
                                    <span>Check In :{{ part.check_in_before }}</span>
                                  </p>
                                  <p v-else>
                                    <span>Check Out :{{ part.check_out_after }}</span>
                                  </p>
                                </div>
                              </div>
                              <div class="content-tooltip" v-else>
                                <p class="lfont">{{ date.rest_day }}</p>
                              </div>
                            </v-tooltip>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--##### ສ່ວນທີ່ການຕັ້ງເວລາສະແກນ ມີການເລືອກການສະແກນມື້ຂອງອາທິດເຂົ້າສະແກນ ##### -->
                </div>
              </div>
              <div v-else :style="{
                paddingLeft: '40px',
                paddingTop: '8px',
                paddingRight: '8px',
                minHeight: '80px',
              }">
                No scan
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <loading v-if="isLoading" />
    <UpdateTimeScan @success="FetchData" @close="dialogR = false" @proccess="(val) => (isLoading = val)"
      :change_time="change_time" :dialogR="dialogR" :change_time_id="change_time_id" :employee_id="employee_id"
      :check_in_schedule_detail_id="check_in_schedule_detail_id" :clock_type="clock_type" :date="date" />
    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteTimeScan :delete_time_id="delete_time_id" @close="close" @success="FetchData" />
      </template>
    </ModalDelete>
    <!-- :change_time="change_time" -->
    <!-- :editData="editData" -->
    <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="FetchData">
    </Pagination>
    <ModalExportAttendanceLog :dialog="dialog_export_attendance_log" :filters="item_export_attendance_log"
      @action="(val) => dialog_export_attendance_log = val" />
    <ModalExportDefault :dialog="dialog_export_default" :filters="item_export_default"
      @action="(val) => dialog_export_default = val" />
  </div>
</template>
<script>
import moment from "moment"; // import xlsx;
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "@/components/Paginate/Pagination";
import UpdateTimeScan from "@/views/Report/Modals/UpdateTimeScan";
import DeleteTimeScan from "@/views/Report/Modals/DeleteTimeScan";
import ModalExportAttendanceLog from "./Components/Modal-export-attendance-log.vue";
import ModalExportDefault from "./Components/Modal-export-default.vue";
export default {
  components: {
    Loading,
    DatePicker,
    Pagination,
    UpdateTimeScan,
    DeleteTimeScan,
    ModalExportAttendanceLog,
    ModalExportDefault,
  },
  mounted() { },

  data: () => ({
    dialogR: false,
    displayBox: "160px",
    offset: 10,
    pagination: {},
    per_page: 10,
    colorFont: "white",
    dates: [],
    parts: [],
    listBranch: [],
    branch_id: "",
    listDepartment: [],
    departmentId: "",
    employees: [],
    change_time_id: "",
    delete_time_id: "",
    change_time: "",
    isLoading: false,
    start_date: new Date().toISOString().slice(0, 10),
    end_date: new Date().toISOString().slice(0, 10),
    // start_date: "2023-01-01",
    // end_date: "2023-01-31",

    item: {
      start_date: "",
      end_date: "",
      schedule_type: "",
    },
    check_in_status: "normal",
    defaultSchedule: {
      name: "byEmployee",
    },
    selectPart: [
      /* {
        name: "byPosition",
      }, */
      {
        name: "byEmployee",
      },
    ],
    searchItem: "",
    employee_id: "",
    check_in_schedule_detail_id: "",
    clock_type: "",
    date: "",
    dialog_export_attendance_log: false,
    item_export_attendance_log: {},
    dialog_export_default: false,
    item_export_default: {},
  }),

  methods: {
    check_time_clock_in(started_at, date, ended_at) {
      if (started_at == null || (moment(date).format('YYYY-MM-DD') >= moment(started_at).format('YYYY-MM-DD') &&
        moment(date).format('YYYY-MM-DD') <= moment(ended_at).format('YYYY-MM-DD'))) {
        return true;
      }
      return false;
    },
    deleteTimeScan(data, clock_date_leave, date, part) {
      let id = null;
      clock_date_leave.forEach((item, index) => {
        if (
          item.date == date.date &&
          part.schedule_detail_id == item.check_in_schedule_detail_id &&
          part.clock_type == item.clock_type
        ) {
          id = item.id;
        }
      });
      if (id == null) {
        data.forEach((item, index) => {
          if (
            item.date == date.date &&
            part.schedule_detail_id == item.check_in_schedule_detail_id &&
            part.clock_type == item.clock_type
          ) {
            id = item.id;
          }
        });
      }

      this.delete_time_id = id;

      this.$store.commit("modalDelete_State", true);
    },

    modalUpdateTime(employee_id, data, clock_date_leave, date, part) {
      let change_time_id = null;
      let change_time = null;
      clock_date_leave.forEach((item, index) => {
        if (
          item.date == date.date &&
          part.schedule_detail_id == item.check_in_schedule_detail_id &&
          part.clock_type == item.clock_type
        ) {
          change_time_id = item.id;
          change_time = item.time_scan;
        }
      });
      if (change_time_id == null) {
        data.forEach((item, index) => {
          if (
            item.date == date.date &&
            part.schedule_detail_id == item.check_in_schedule_detail_id &&
            part.clock_type == item.clock_type
          ) {
            change_time_id = item.id;
            change_time = item.time_scan;
          }
        });
      }
      this.change_time_id = change_time_id;
      this.change_time = change_time;
      this.dialogR = true;
      this.employee_id = employee_id;
      this.date = date.date;
      this.check_in_schedule_detail_id = part.schedule_detail_id;
      this.clock_type = part.clock_type;
    },
    editEmployeeClockIn(item) { },
    getElementHeight(part) {
      if (part != undefined) {
        return 4 * part.length + "0px";
      } else {
        return "80px";
      }
    },
    fetchDepartment() {
      this.$axios
        .get(`company/list/departments/selected`, {})
        .then((res) => {
          if (res.status === 200) {
            this.listDepartment = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    searchFilterItem() {
      this.FetchData();
    },
    getFontColor(color) {
      if (color == "white") {
        return "black";
      } else if (color == "#fff") {
        return "orange";
      } else {
        return "white";
      }
    },
    handleEventFilterDate(event, type) {
      if (type === "start") {
        this.item.start_date = event;
      } else if (type === "end") {
        this.item.end_date = event;
      } else {
        this.item.schedule_type = event;
      }
      const items = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule || this.item.schedule_type,
      };
      this.FetchData(items);
    },
    exportAttendanceCorrection() {
      const item = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule.name || this.item.schedule_type,
        name: this.searchItem,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        check_in_status: this.check_in_status,
      };
      this.isLoading = true;
      this.$axios
        .post(`export/clockin/attendance/correction`, item, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute(
            "download",
            "fingerscan acttendance correction.xlsx"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    exportStatistic() {
      const item = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule.name || this.item.schedule_type,
        name: this.searchItem,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        check_in_status: this.check_in_status,
      };
      this.isLoading = true;
      this.$axios
        .post(`export/clockin/statistics`, item)
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    exportTotalOfAttendance() {
      const item = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule.name || this.item.schedule_type,
        name: this.searchItem,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        check_in_status: this.check_in_status,
      };
      this.isLoading = true;
      this.$axios
        .post(`export/clockin/totalOfAttendance`, item)
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    exportInOut() {
      const item = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule.name || this.item.schedule_type,
        name: this.searchItem,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        check_in_status: this.check_in_status,
      };
      this.isLoading = true;
      this.$axios
        .post(`export/clockin/check-in-out`, item)
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    exportAttendanceLog() {
      this.item_export_attendance_log = {
        check_in_status: this.check_in_status,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        schedule_type: "byEmployee",
        name: this.searchItem,
        start_date: this.start_date,
        end_date: this.end_date,
        step: "",// info, parts, clock_in_data, leaves
        tracking_id: ""
      };
      this.dialog_export_attendance_log = true;
    },

    exportClockInOutDefault() {
      this.item_export_default = {
        start_date: this.start_date || this.item.start_date,
        end_date: this.end_date || this.item.end_date,
        schedule_type: this.defaultSchedule.name || this.item.schedule_type,
        name: this.searchItem,
        branchId: this.branch_id,
        departmentId: this.departmentId,
        check_in_status: this.check_in_status,
        step: "",// info, parts, clock_in_data, clock_date_leave, leaves, weeklyDayOff
        tracking_id: ""
      };
      this.dialog_export_default = true;
    },

    getTimeScan(employee, part, date) {
      let isScan = "";
      //ສະແດງເວລາສະແກນເວລາມີການຂໍລາພັກ

      let current_date_leave = employee.clock_date_leave.filter(item => item.date == date.date);
      for (const element of current_date_leave) {
        // ຖ້າບໍ່ແມ່ນມື້ພັກ
        if (element.check_in_schedule_detail_id == part.schedule_detail_id) {
          if (element.clock_type == part.clock_type && element.is_skip == 0) {
            if ((isScan = element.time_scan)) {
              return [{ data: element.time_scan, status: true }];
            }
          } else if (element.is_skip == 1) {
            return [{ data: "ບໍ່ສະແກນ", status: true }];
          }
        }
      }

      //ສະແດງເວລາສະແກນປົກກະຕິ
      let current_date = employee.dates.filter(item => item.date == date.date);
      for (const element of current_date) {
        // ຖ້າບໍ່ແມ່ນມື້ພັກ
        if (element.check_in_schedule_detail_id == part.schedule_detail_id) {
          if (element.clock_type == part.clock_type && element.is_skip == 0) {
            if ((isScan = element.time_scan)) {
              return [{ data: element.time_scan, status: true }];
            }
          } else if (element.is_skip == 1) {
            return [{ data: "ບໍ່ສະແກນ", status: true }];
          }
        }
      }
      if (date.rest_day != null) {
        // ຄົ້ນຫາຜູທີ່ຕ້ອງມາວຽກໃນມື້ພັກ
        let empRest = 0;
        if (date.empWork != null) {
          for (const empItem of date.empWork) {
            if (empItem.id == employee.id) {
              empRest = 1;
              break;
            }
          }
        }

        if (empRest == 0)
          return [
            {
              data: date.rest_day,
              status: false,
            },
          ];
      }
      return [{ data: "", statsus: true }];
    },

    getColor(employee, part, date) {
      let color = "red";
      if (date.rest_day != null) {
        let empRest = 0;
        if (date.empWork != null) {
          for (const empItem of date.empWork) {
            if (empItem.id == employee.id) {
              empRest = 1;
              break;
            }
          }
        }

        if (empRest == 0) color = "white";
      }
      employee.dates.forEach((element) => {
        if (element.date == date.date) {
          if (element.check_in_schedule_detail_id == part.schedule_detail_id) {
            if (element.clock_type == part.clock_type) {
              if (element.is_skip > 0) {
                color = "#fff";
              } else if (element.is_absense > 0) {
                color = "orange";
              } else if (element.time_difference > 0) {
                color = "orange";
              } else {
                color = "white";
              }
            }
          }
        }
      });
      for (const item of employee.leaves) {
        if (
          moment(date.date).format("YYYY-MM-DD") >=
          moment(item.start_date).format("YYYY-MM-DD") &&
          moment(date.date).format("YYYY-MM-DD") <=
          moment(item.end_date).format("YYYY-MM-DD")
        ) {
          if (item.detail.length > 0) {
            for (const itemDetail of item.detail) {
              if (
                moment(date.date).format("YYYY-MM-DD") ==
                moment(itemDetail.date).format("YYYY-MM-DD")
              ) {
                color = "#80acf4";
              }
            }
          } else {
            color = "#80acf4";
          }
        }
      }

      return color;
    },
    FetchData(item) {
      let data = {
        start_date: this.start_date || item.start_date,
        end_date: this.end_date || item.end_date,
        schedule_type: this.defaultSchedule.name || item.schedule_type,
        per_page: this.per_page,
        page: this.pagination.current_page,
        name: this.searchItem,
        departmentId: this.departmentId,
        branchId: this.branch_id,
        check_in_status: this.check_in_status,
      };
      this.isLoading = true;
      this.$axios
        .post("report/clockin/employees", data)
        .then((res) => {
          this.isLoading = false;
          this.dates = res.data.currentDates;
          this.parts = res.data.parts;
          this.employees = res.data.employees.data;
          this.pagination = res.data.paginate;
          if (!this.employees.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
    this.FetchData();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.container-report {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  background-color: #ffffff;
  overflow: hidden;
  // position: relative;
  border: 1px solid #eeeeee;

  .btn-export {
    .success {
      font-size: 12px !important;
      height: 30px !important;
    }

    .primary {
      margin-top: 25px !important;
    }
  }

  h1 {
    font-family: $font-family;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 10px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  .card-filter {
    width: 100%;
    height: auto;
    padding: 30px 0px 0 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 12%);
    border: 1px solid #eeeeee;
    // position: fixed;
    top: 0;
    z-index: 1;

    .card-color-detail {
      width: 100%;
      height: 60px;

      .color-detail {
        width: 100%;
        height: 100%;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        border-radius: 16px;
        padding: 10px 10px;
        font-size: 14px !important;

        .circle-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: red;
        }

        p {
          font-family: $font-family;
          margin-bottom: 0;
          padding-left: 20px;
        }

        .standard {
          background-color: #ffffff;
          border: 1px solid #000000;
        }

        .is-leave {
          background-color: #80acf4;
        }

        .is-absence {
          background-color: red;
        }

        .is-skip {
          background-color: #fd9644;
        }

        .is-time-difference {
          background-color: #2f3542;
        }
      }
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  border-collapse: separate;
  border-spacing: 0;
}

//start freezed
#mytable th {
  position: sticky;
  top: 0;
  min-width: 120px !important;
  color: rgb(0, 159, 255);
  z-index: 1;
}

#mytable th:nth-child(1),
#mytable td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2 !important;
}

#mytable th:nth-child(2),
#mytable td:nth-child(2) {
  position: sticky;
  left: 120px;
  min-width: 170px !important;
  z-index: 2 !important;
}

#mytable th:nth-child(1),
#mytable th:nth-child(2) {
  background: rgb(0, 159, 255);
}

#mytable td:nth-child(2),
#mytable th:nth-child(2) {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75) !important;
  clip-path: inset(0px -15px 0px 0px) !important;
}

#mytable td:nth-child(2),
#mytable th:nth-child(2) {
  border-right: 1px solid black !important;
}

//end freezed


.showScroll {
  overflow: scroll;
  // overflow-y: auto;
  // border: 1px solid black;
  // height: auto;
  // width: auto;
  // line-height: 1em;
}

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}

.custom-bg-tooltip {
  background-color: rgb(106, 187, 245) !important;
}
</style>
